import { ref } from "vue";
import { useRootStore } from "@/store/root";

interface LoadingOptions {
  global?: boolean;
}

export function useLoading(options: LoadingOptions = {}) {
  const { appLoading } = storeToRefs(useRootStore());
  const isLoading = ref(false);
  const { global = false } = options;

  async function withLoader<T>(fn: (...args: any[]) => Promise<T>, ...args: any[]) {
    try {
      isLoading.value = true;
      if (global) appLoading.value = true;
      const result = await fn(...args);

      if (typeof result === "function") {
        return result();
      }
    } catch (err) {
      throw err;
    } finally {
      if (global) appLoading.value = false;
      isLoading.value = false;
    }
  }

  return {
    withLoader: (fn: (...args: any[]) => Promise<any>) => {
      return async (...args: any[]) => {
        await withLoader(fn, ...args);
      };
    },
    isLoading,
  };
}
